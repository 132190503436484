<template>
  <div id="about">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <HeadSections class="about-img" :TitlePage="$t('Get to know the Etmaam family !')"
      :TitleSec="this.$i18n.locale == 'ar' ? 'كن على تواصل دائم لكل ما هو جديد.' : 'Stay in touch with everything new.'"
      :PathPageFrom="$t('Home')" :PathPageTo="$t('About')" />
    <div class="container" style=" overflow: hidden;margin-top: -130px;">
      <AboutCompany />
    </div>
      <section class="pt-5 pb-5 mt-5 mb-5" style="background-color: #f3f7f9;">
            <div class="container">
            <TitleSection :title="$t('We appeared on these sites !')" :TextAlign="`center`" />
              <div class="row">
                <div class="col-md-2 mb-3 col-6 d-flex align-items-center"  v-for="item in Partners.slice(0,6)" :key="item.id">
               <div class="item w-100">
                <img class="w-100 gray-hover-img" :src="item.image" alt="">
               </div>
                </div>
              </div>
            </div>
      </section>
      <Testimonials />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
export default {
  name: 'About',
  components: {
    TitleSection: defineAsyncComponent( () => import('@/components/Global/TitleSection.vue')),
    HeadSections: defineAsyncComponent(() => import('@/components/Global/HeadSections.vue')),
    AboutCompany: defineAsyncComponent(() => import('@/components/About/AboutCompany.vue')),
    Testimonials: defineAsyncComponent(() => import('@/components/Home/Testimonials.vue')),
    Partner: defineAsyncComponent(() => import('@/components/Home/Partner.vue')),
  },
  computed:{
        Partners(){
            return this.$store.state.Partners
        }
    },
  mounted(){
        this.$store.dispatch('GetPartners');
    }
}
</script>
